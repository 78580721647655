<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn02 bw5" tag="button"  :to="{path:'/leisure/fx1m', query: {t: new Date().getTime()}}">FX 1M</router-link>
        <router-link class="btn02 bw5" tag="button"  :to="{path:'/leisure/fx2m', query: {t: new Date().getTime()}}">FX 2M</router-link>
        <router-link class="btn02 bw5" tag="button"  :to="{path:'/leisure/fx3m', query: {t: new Date().getTime()}}">FX 3M</router-link>
        <router-link class="btn02 bw5" tag="button"  :to="{path:'/leisure/fx4m', query: {t: new Date().getTime()}}">FX 4M</router-link>
        <router-link class="btn02 bw5" tag="button"  :to="{path:'/leisure/fx5m', query: {t: new Date().getTime()}}">FX 5M</router-link>
    </div>
</template>

<script>
    export default {
        name: "LeisureFxLinks"
    }
</script>

<style scoped>

</style>