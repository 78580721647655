import { render, staticRenderFns } from "./LeisureFxLinks.vue?vue&type=template&id=45685b25&scoped=true&"
import script from "./LeisureFxLinks.vue?vue&type=script&lang=js&"
export * from "./LeisureFxLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45685b25",
  null
  
)

export default component.exports